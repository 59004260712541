.table {
  margin-top: 16px;

  &--center {
    text-align: center;
  }

  &__td {
    cursor: pointer;
  }

  &__iconClose {
    cursor: pointer;
    color: red;
  }

  &__iconRestore {
    cursor: pointer;
    color: #444;
  }

  &__iconPassword {
    cursor: pointer;
    color: orangered;
  }
}
