.table {
  margin-top: 16px;

  &--center {
    text-align: center;
  }

  &__td {
    cursor: pointer;
  }

  &__iconClose {
    cursor: pointer;
    color: red;
  }

  &__iconRestore {
    cursor: pointer;
    color: #444;
  }
}

.contentButtons {
  display: flex;

  &__add {
    width: 50%;
  }

  &__excel {
    display: flex;
    width: 50%;
    justify-content: flex-end;

    &__input {
      margin-right: 10px;
    }

    &__download {
      color: #444;
      text-decoration: underline;
      cursor: pointer;
    }

    &__label {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.contentSearch {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  margin-top: 1rem;

  &__search {
    width: 50%;
  }

  &__options {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.cantExcel {
  display: flex;
  justify-content: flex-end;
}
