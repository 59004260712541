.login {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  &__center {
    width: 25%;
  }
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  font-size: 50px;
}
