@import "../../variables";

.main {
  padding: 10px;
  margin-left: $width-aside;
  margin-top: 30px;
}

.sede {
  margin-left: 290px;
  position: absolute;
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: red;
}
