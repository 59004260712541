@import "../../../variables";

.tr {
  &:focus-visible {
    background: yellowgreen;
  }

  &:hover {
    background: yellowgreen;
  }

  &:visited {
    background: yellowgreen;
  }
}

// .contentRuc {
//   display: block;

//   .contentData {
//     display: flex;
//     align-items: center;
//     text-align: center;
//     flex-direction: column;
//     margin-right: 20px;
//     border: 1px solid #000;
//   }
// }

@include for_breakpoint(small_laptop) {
  tbody {
    line-height: 1;
  }

  .contentTable {
    // font-size: 12px;
  }
}

.table {
  margin-top: 16px;

  &--center {
    text-align: center;
  }

  &__td {
    cursor: pointer;
  }

  &__iconClose {
    cursor: pointer;
    color: #fff;
  }

  &__iconRestore {
    cursor: pointer;
    color: #444;
  }
}

//ticket
@media print {
  .content {
    padding: 5px;
    color: #000;
    font-family: sans-serif;

    .ticket {
      width: 302px;
      max-width: 302px;
      font-size: 10px;

      &__img {
        max-width: inherit;
        width: inherit;
      }

      &__centrado {
        text-align: center;
        align-content: center;
        margin-top: 1rem;
      }

      &__centrado_noMargin {
        text-align: center;
        align-content: center;
      }

      &__centrado_Table {
        display: flex;
        justify-content: center;
        text-align: start;
        margin-top: 1rem;
      }

      &__caja {
        width: 302px;
        max-width: 302px;
        margin-bottom: 1rem;
      }

      &__titulo_pago {
        margin-left: 40px;
        width: 75px;
        max-width: 75px;
        float: left;
      }

      &__numeros {
        margin-left: 40px;
        width: 40px;
        max-width: 40px;
        float: left;
      }

      .cantidad {
        width: 40px;
        max-width: 40px;
        word-break: break-all;
      }

      .producto {
        width: 140px;
        max-width: 140px;
      }

      .descuento {
        width: 40px;
        max-width: 40px;
        word-break: break-all;
        text-align: end;
      }

      .pu {
        width: 30px;
        max-width: 30px;
        word-break: break-all;
        text-align: start;
      }

      .total {
        width: 20px;
        max-width: 20px;
        word-break: break-all;
        text-align: end;
      }

      table tr td {
        border: none;
      }

      table thead {
        border-top: 1px dotted black;
        border-bottom: 1px dotted black;
        border-collapse: collapse;
      }

      &__borderTR {
        border-top: 1px dotted black;
        border-bottom: 1px dotted black;
        border-collapse: collapse;
      }

      &__tr {
        border: hidden;
      }

      &__soles {
        text-align: end;
      }

      .iconAndSoles {
        display: flex;

        &__soles {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }

      .canceled {
        position: absolute;
        color: red;
        opacity: 0.15;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        transform: rotate(-45deg);
      }
    }
  }
}
